// @ts-check
import React from "react"

const Workflow2 = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
    <rect x="68" width="60" height="60" rx="8" fill="#fff" />
    <mask id="a" fill="#fff">
      <rect x="80.9668" y="16.4805" width="33.4868" height="27.1695" rx="2" />
    </mask>
    <rect
      x="80.9668"
      y="16.4805"
      width="33.4868"
      height="27.1695"
      rx="2"
      stroke="currentColor"
      strokeWidth="5.6"
      mask="url(#a)"
    />
    <path
      d="M82.5308 18.2766l13.8582 12.194c.7554.6647 1.8869.6647 2.6423 0l13.8587-12.194"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect y="68" width="60" height="60" rx="8" fill="#fff" />
    <mask id="b" fill="#fff">
      <rect x="15.1279" y="75.2256" width="30.6017" height="43.7744" rx="2" />
    </mask>
    <rect
      x="15.1279"
      y="75.2256"
      width="30.6017"
      height="43.7744"
      rx="2"
      stroke="currentColor"
      strokeWidth="5.6"
      mask="url(#b)"
    />
    <path
      stroke="currentColor"
      strokeWidth="2.8"
      d="M21.2933 81.7809h18.2702V105.6H21.2933z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2.6"
      d="M28.832 98.424h-5.4869M37.5117 93.0021l-5.4869.0001M28.832 87.9277h-5.4869"
    />
    <path
      d="M30.3818 114c1.3807 0 2.5-1.119 2.5-2.5s-1.1193-2.5-2.5-2.5-2.5 1.119-2.5 2.5 1.1193 2.5 2.5 2.5z"
      fill="currentColor"
    />
    <rect x="68" y="68" width="60" height="60" rx="8" fill="#fff" />
    <mask id="c" fill="#fff">
      <rect x="77.3646" y="81.2617" width="41.9994" height="33.1277" rx="2" />
    </mask>
    <rect
      x="77.3646"
      y="81.2617"
      width="41.9994"
      height="33.1277"
      rx="2"
      stroke="currentColor"
      strokeWidth="5.6"
      mask="url(#c)"
    />
    <path
      d="M77.3645 91.3633h42.0005"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="83.1744" cy="86.8534" r="1.4" fill="currentColor" />
    <path
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinecap="round"
      d="M91.0938 86.8534H107.33"
    />
    <rect width="60" height="60" rx="8" fill="#fff" />
    <mask id="d" fill="#fff">
      <path d="M21.113 44.3369c6.7635 4.2584 15.8064 3.4426 21.6965-2.4476 6.8372-6.8371 6.8372-17.9223 0-24.7595-6.8372-6.8371-17.9224-6.8371-24.7595 0-5.8545 5.8545-6.6959 14.8236-2.5244 21.5734l-.4712 1.5396-.8749 2.8592-.9495 3.1025 3.1571-.748 3.1376-.7433 1.5883-.3763z" />
    </mask>
    <path
      d="M42.8095 41.8893l1.9799 1.9799-1.9799-1.9799zM21.113 44.3369l1.4918-2.3695-.9941-.6259-1.1432.2708.6455 2.7246zm21.6965-27.2071l-1.9799 1.9799 1.9799-1.9799zm-24.7595 0l1.9799 1.9799-1.9799-1.9799zm-2.5244 21.5734l2.6774.8193.3711-1.2126-.6667-1.0787-2.3818 1.472zm-.4712 1.5396l-2.6774-.8193 2.6774.8193zm-.8749 2.8592l-2.6775-.8193 2.6775.8193zm-.9495 3.1025l-2.6774-.8194-1.42905 4.6698 4.75195-1.1259-.6455-2.7245zm3.1571-.748l-.6455-2.7246.6455 2.7246zm3.1376-.7433l-.6454-2.7246.6454 2.7246zm21.3049-4.8038c-4.9456 4.9456-12.5433 5.6352-18.2248 2.058l-2.9837 4.739c7.8456 4.9397 18.3337 3.9975 25.1683-2.8372l-3.9598-3.9598zm0-20.7997c5.7437 5.7437 5.7437 15.0561 0 20.7997l3.9598 3.9598c7.9306-7.9306 7.9306-20.7887 0-28.7193l-3.9598 3.9598zm-20.7997 0c5.7437-5.7437 15.056-5.7437 20.7997 0l3.9598-3.9598c-7.9306-7.9306-20.7887-7.9306-28.7193 0l3.9598 3.9598zm-2.1225 18.1215c-3.5043-5.6702-2.7931-13.2059 2.1225-18.1215l-3.9598-3.9598c-6.79334 6.7933-7.76508 17.1959-2.9263 25.0253l4.7636-2.944zm-5.0592.6526l-.4712 1.5397 5.3549 1.6387.4711-1.5397-5.3548-1.6387zm-.4712 1.5397l-.875 2.8592 5.3549 1.6387.875-2.8592-5.3549-1.6387zm-.875 2.8592l-.9494 3.1024 5.3549 1.6387.9494-3.1024-5.3549-1.6387zm2.3735 6.6463l3.1571-.7479-1.291-5.4492-3.1571.748 1.291 5.4491zm3.1571-.7479l3.1376-.7434-1.2909-5.4491-3.1377.7433 1.291 5.4492zm3.1376-.7434l1.5883-.3762-1.291-5.4492-1.5882.3763 1.2909 5.4491z"
      fill="currentColor"
      mask="url(#d)"
    />
    <path
      d="M21.4355 32.5425l5.3497-6.437 7.2881 6.437 5.3497-6.437"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
)

export default Workflow2
