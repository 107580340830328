// @ts-check
import React from "react"

const Workflow1 = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
    <rect width="128" height="128" rx="24" fill="#fff" />
    <path
      d="M42.7918 91.4313c1.5648 0 2.8334-1.2685 2.8334-2.8333s-1.2686-2.8334-2.8334-2.8334c-1.5648 0-2.8333 1.2686-2.8333 2.8334 0 1.5648 1.2685 2.8333 2.8333 2.8333zM76.7918 91.4313c1.5648 0 2.8334-1.2685 2.8334-2.8333s-1.2686-2.8334-2.8334-2.8334c-1.5648 0-2.8333 1.2686-2.8333 2.8334 0 1.5648 1.2685 2.8333 2.8333 2.8333zM61.2083 71.5977H54.125v2.8333h7.0833v-2.8333zM61.2083 77.2646H54.125v2.8334h7.0833v-2.8334z"
      fill="currentColor"
    />
    <path
      d="M89.5417 29.0977c-2.4084 0-4.25 1.8416-4.25 4.25v38.25H73.9583v-2.8334h4.25V61.681c0-2.4083-1.8416-4.25-4.25-4.25v-5.6667h-8.5v5.6667h-17c-2.4083 0-4.25 1.8417-4.25 4.25v7.0833h4.25v13.4584c-3.4-3.1167-8.7833-2.8334-11.9.5666h-.85c-3.9666 0-7.0833 3.1167-7.0833 7.0834v4.25h7.7917c3.1166 3.5416 8.5 3.825 11.9.7083l.7083-.7083h21.3917c3.1166 3.5416 8.5 3.825 11.9.7083l.7083-.7083h3.6833c3.9667 0 7.0834-3.1167 7.0834-7.0834v-1.4166h11.3333V68.7643H93.7917V37.5977h8.5003v-8.5H89.5417zm-1.4167 4.25c0-.85.5667-1.4167 1.4167-1.4167h1.4166v2.8333H88.125v-1.4166zM85.2917 74.431v8.5h-2.125c-2.2667-2.55-5.95-3.5417-9.2084-2.4083V74.431h11.3334zM71.125 77.2643h-4.25V74.431h4.25v2.8333zm-2.8333-22.6666h2.8333v2.8333h-2.8333v-2.8333zm-21.25 11.3333v-4.25c0-.85.5666-1.4167 1.4166-1.4167h25.5c.85 0 1.4167.5667 1.4167 1.4167v4.25H47.0417zm24.0833 2.8333v2.8334h-7.0833v8.5h7.0833v2.125c-.2833.1416-.425.425-.7083.7083h-19.125V68.7643H71.125zM31.4583 91.431v-1.4167c0-1.9833 1.4167-3.6833 3.2584-4.1083-.5667 1.8417-.5667 3.825 0 5.525h-3.2584zm11.3334 2.8333c-3.1167 0-5.6667-2.55-5.6667-5.6666 0-3.1167 2.55-5.6667 5.6667-5.6667 3.1166 0 5.6666 2.55 5.6666 5.6667 0 3.1166-2.55 5.6666-5.6666 5.6666zm7.9333-2.8333c.7083-1.8417.7083-3.825 0-5.6667h17.9917c-.7084 1.8417-.7084 3.825 0 5.6667H50.725zm26.0667 2.8333c-3.1167 0-5.6667-2.55-5.6667-5.6666 0-3.1167 2.55-5.6667 5.6667-5.6667 3.1166 0 5.6666 2.55 5.6666 5.6667 0 3.1166-2.55 5.6666-5.6666 5.6666zm14.1666-7.0833c0 2.4083-1.8416 4.25-4.25 4.25h-1.8416c.7083-1.8417.7083-3.825 0-5.6667h6.0916v1.4167zm11.3337-15.5833V82.931h-8.5003V71.5977h8.5003zM90.9583 82.931H88.125V37.5977h2.8333V82.931zm2.8334-48.1667V31.931h5.6666v2.8333h-5.6666z"
      fill="currentColor"
    />
    <path
      d="M99.4583 74.4307H96.625v2.8333h2.8333v-2.8333zM34.0505 43.0742c-.8561 0-1.55.694-1.55 1.55 0 .8561.6939 1.55 1.55 1.55.856 0 1.55-.6939 1.55-1.55 0-.856-.694-1.55-1.55-1.55z"
      fill="currentColor"
    />
    <path
      d="M33.481 26.7156c.2309-.4.8083-.4 1.0392 0L47.6194 49.404c.2309.4-.0577.9-.5196.9H20.9014c-.4618 0-.7505-.5-.5196-.9L33.481 26.7156z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M32.6006 41.4521v-7h2.8v7h-2.8z" fill="currentColor" />
  </svg>
)

export default Workflow1
