import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import styled from "@emotion/styled"
import Typography from "../components/typography"
import colors from "../styles/colors"
import { RequestDemoForm } from "../components/RequestDemoForm"
import { CardSplitImageText } from "../components/cards/CardSplitImageText"
import { CardMoreInfo } from "../components/cards/requestDemo/cardMoreInfo"
import { MobileMediaQuery } from "../styles/constants"
import { CardPlugAndPlay } from "../components/cards/tools/cardPlugAndPlay"
import CardPartnersB2b from "../components/cards/cardPartnersB2b"

const FullWidthAlignment = styled.div`
  display: flex;
  width: 100%;

  ${MobileMediaQuery} {
    flex-direction: column;
    align-items: center;
  }
`
const Left = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MobileMediaQuery} {
    text-align: center;
    margin-bottom: 3rem;
  }
`
const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const FullWidthCard = styled(Card)`
  padding-left: 8px !important;
  padding-right: 8px !important;
`

const FullWidth = ({ title, description, rightComponent }) => (
  <FullWidthCard
    variant="lightpurple"
    style={{
      width: "100vw",
      maxWidth: "100vw",
      borderRadius: 0,
      marginLeft: "-4px",
      marginRight: "-4px",
    }}
  >
    <FullWidthAlignment>
      <Left>
        <Typography
          variant="h2"
          style={{ color: colors.primary, marginBottom: "4rem" }}
        >
          {title}
        </Typography>
        <Typography variant="h6">{description}</Typography>
      </Left>
      <Right>{rightComponent}</Right>
    </FullWidthAlignment>
  </FullWidthCard>
)

const Image = ({ fluid, width = 100 }) => {
  return (
    <div style={{ display: "table", width: width + "%", padding: "2rem" }}>
      <div style={{ display: "table-cell", verticalAlign: "middle" }}>
        <Img fluid={fluid} style={{}} />
      </div>
    </div>
  )
}

const B2B = () => {
  const data = useStaticQuery(graphql`
    query {
      drone: file(relativePath: { eq: "tools/drone_square.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 950
            quality: 80
            traceSVG: { background: "#DDDDDE", color: "#232322" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      fts: file(relativePath: { eq: "try-it-free/fts.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 950
            quality: 100
            traceSVG: { background: "transparent", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      decisionSupport: file(
        relativePath: { eq: "tools/decision_support.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 952
            quality: 80
            traceSVG: { background: "transparent", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout bg="white" newsletter={null}>
      <SEO title="B2B request a demo" />
      <FullWidth
        title="Product support built for complex problems."
        description={
          <>
            Mavenoid works in tandem with your service team to provide the
            ultimate support experience.
            <br />
            <br />
            Reduce technician overhead, get insight into repetitive issues, and
            service the customers you can’t reach with Mavenoid’s AI-powered
            Product Assistant and Live Support tools.
          </>
        }
        rightComponent={
          <RequestDemoForm
            from="B2B-LP"
            formTitle="Request a demo to learn more"
          />
        }
      />
      <CardPartnersB2b variant="white" />
      <CardSplitImageText
        isReverse
        title="Live support tools that get to solutions, faster"
        description={
          <>
            Mavenoid’s Live Support gives your service teams the tools they need
            to solve your customer’s most complex problems. <br /> <br />
            One-touch video calls, live chat and AI-powered recommendations
            empower your teams to provide the best customer support ever.
          </>
        }
        variant="primary"
        fluid={data.drone.childImageSharp.fluid}
      />
      <CardSplitImageText
        title="Support automation that actually works"
        description={
          <>
            Mavenoid’s Product Assistant uses the power of AI to automate and
            solve even the most complex technical problems— something chatbots
            can only dream of. <br />
            <br />
            With powerful features like natural language processing, flexible
            support flows and seamless escalation, you can provide phenomenal
            service without burdening your service team.
          </>
        }
        variant="lightpurple"
        image={<Image fluid={data.fts.childImageSharp.fluid} width={90} />}
      />
      <CardSplitImageText
        isReverse
        title="Take your service team to the next level"
        description={
          <>
            Features such as AI-powered decision support, built-in feedback
            loops, and the resource library allow your team to build a support
            knowledge base.
            <br />
            <br /> Address knowledge silos and expert scarcity with a support
            platform that can solve diverse technical problems.
          </>
        }
        variant="primary"
        image={<Image fluid={data.decisionSupport.childImageSharp.fluid} />}
      />

      <CardPlugAndPlay />
      <CardMoreInfo />
    </Layout>
  )
}

export default B2B
